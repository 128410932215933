import { Button } from "@mui/material";

import { useNavigate, useLocation } from 'react-router-dom';

import Footer from "./Footer";

export default function MainPage(params) {

    const navigate = useNavigate();
    return (
        <>

            <div style={{ width: '100vw', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                <h1 id="store-name">Godx Official Store</h1>
            </div>
            <div className="title-div">
                <div class="cardWrapper">
                    <img className="title-image" src="/pubg.jpg" />
                </div>
                <div class="cardWrapper" style={{ overflow: 'visible' }}>
                    <img className="title-image" src="/mlbb.jpg" />
                </div>
                <div class="cardWrapper">
                    <img className="title-image" src="/genshin.jpg" />
                </div>
            </div>


            <hr style={{ width: '70vw' }} />

            <div style={{ width: '100vw', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                <h3 >Games</h3>
            </div>

            <div className="games-div">
                <div className="each-game">
                    <img className="game-image" src="./mlbb.jpeg" />
                    <Button variant="contained" onClick={() => { navigate('/mlbb'); }}>Go to recharge</Button>
                </div>

            </div>

            <Footer />
        </>
    );
}