
import PwaWithInstallButton from './PwaWithInstallButton';

export default function Footer(params) {

    return (
        <>
            <div className="footer-div">
                <span>All Rights Reserved @TheCodeFather 2024-25</span>

                <PwaWithInstallButton />
            </div>

        </>
    )
}