const config_json = {
  API: {
    GraphQL: {
      endpoint: 'https://dvdujqow5bfvjika5w3i5fbfbi.appsync-api.ap-south-1.amazonaws.com/graphql',
      region: "ap-south-1",
      // Set the default auth mode to "apiKey" and provide the API key value
      defaultAuthMode: 'apiKey',
      apiKey: "da2-uthia2s5rvbl3pv3dewfdckxha"
    }
  }
    // "aws_appsync_graphqlEndpoint":"https://o2hcqwqjk5fyvb2x42il4vfbv4.appsync-api.ap-south-1.amazonaws.com/graphql",
    // "aws_appsync_region": "ap-south-1",
    // "aws_appsync_authenticationType": "API_KEY",
    // "aws_appsync_apiKey": "da2-iffpuhjixzdslhzyx4xaxzsgby"
  };
  
  export default config_json;
  