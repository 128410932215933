

import Button from '@mui/material/Button';

import NewNavbar from './Navbar';
export default function Fail() {
    return (
        <>

            <img className="mlbb-image" src="/mlbb.jpg" />

            <div className='full-screen'>
                {/* <div className='main-div'>
                    <span style={{ color: "#00f0ff", fontSize: "1.2rem" }}>GodxOfficial Store</span>
                    <hr style={{ borderColor: '#00f0ff' }}></hr>
                </div> */}
                <div className='info-container' style={{ position: 'relative', zIndex: '9' }} initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} >
                    <h1 id="store-name" style={{ textAlign: 'center' }}>Godx Official Store</h1>
                    <p style={{ color: "black", fontSize: "0.8rem", marginBottom: '-1rem', textAlign: 'center' }}>Payment Failed!</p>
                    <br />
                    <p style={{ color: "black", fontSize: "0.5rem", lineHeight: '0.8rem', textAlign: 'center' }}>Please try to recharge again</p>
                    <img alt='Thank you' width="210" height="210" style={{ marginTop: "5vh", marginLeft: '15%' }} src="/miya_cry.gif" />
                    {/* <br /> */}
                    {/* <Button variant='contained' style={{ marginTop: "1rem", marginLeft: '36%' }} >Home</Button> */}
                </div>
            </div>
            <NewNavbar view="history" />

        </>)

}