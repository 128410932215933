/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGodxItems = /* GraphQL */ `
  query GetGodxItems($itemname: String!) {
    getGodxItems(itemname: $itemname) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const listGodxItems = /* GraphQL */ `
  query ListGodxItems(
    $filter: TableGodxItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGodxItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        itemname
        isactive
        storeitems
        ef1
        ef2
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGodxTransactions = /* GraphQL */ `
  query GetGodxTransactions($trxid: String!) {
    getGodxTransactions(trxid: $trxid) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const listGodxTransactions = /* GraphQL */ `
  query ListGodxTransactions(
    $filter: TableGodxTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGodxTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        trxid
        timedate
        user
        trxstatus
        purchaseitems
        amount
        ef1
        ef2
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGodxUserHistory = /* GraphQL */ `
  query GetGodxUserHistory($userid: String!) {
    getGodxUserHistory(userid: $userid) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
export const listGodxUserHistories = /* GraphQL */ `
  query ListGodxUserHistories(
    $filter: TableGodxUserHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGodxUserHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userid
        orders
        subscription
        isblocked
        __typename
      }
      nextToken
      __typename
    }
  }
`;
