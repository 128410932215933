
import React, { useEffect, useState } from "react";

import { generateClient } from 'aws-amplify/api';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { PaymentHnadler, createPaymentLink } from "./PaymentHandler";

import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Swal from 'sweetalert2';

import Footer from "./Footer"
import NewNavbar from "./Navbar";

import { getGodxItems } from '../graphql/queries';



const client = generateClient();

export default function Mlbb(params) {

    const [stepOneError, setStepOneError] = useState(false);
    const [stepOneErrorMessage, setStepOneErrorMessage] = useState("");
    const [stepTwoError, setStepTwoError] = useState(false);
    const [recharging, setRecharging] = useState(false);
    const [rechargeWarning, setRechargeWarning] = useState(false);
    const [myAmountDict, setMyAmountDict] = useState([]);
    const [isLive, setIsLive] = useState("load");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailID, setEmailID] = useState("");
    const [userInfoSaved, setUserInfoSaved] = useState(false);
    const [showID, setShowID] = useState(false);
    const [userid, setUserID] = useState("");
    const [serverid, setServerID] = useState("");
    const [selectedValue, setSelectedValue] = useState();
    const [amount, setAmount] = useState();
    const [ign, setIgn] = useState('');
    const [selectedPack, setSelectedPack] = useState({});

    useEffect(() => {
        getPack();
        try {
            setEmailID(localStorage.getItem('emailid'));
            setPhoneNumber(localStorage.getItem('phoneno'))
        } catch (error) {

        }

    }, [])

    const handleChange = (event) => {
        setSelectedValue(JSON.parse(event.target.value)['item']);
        setAmount(JSON.parse(event.target.value)['price'])
    };

    const handlePackSelect = (event) => {
        setSelectedPack(event.target.value);
        console.log(event.target.value)
    }

    async function getPack() {

        const apiData = await client.graphql({
            query: getGodxItems,
            variables: {
                itemname: 'store-diamonds',
            },
        });
        if (apiData["data"]['getGodxItems']) {
            setMyAmountDict(JSON.parse(apiData["data"]['getGodxItems']['storeitems']))
            setIsLive(apiData["data"]['getGodxItems']['isactive'])
        }

    }

    const getIGN = () => {
        if (userid === "" || userid === "") {
            Swal.fire({
                icon: "error",
                title: "Please enter user id and zone id!",
            });
        } else {
            setIgn("");
            // setLoading(true);
            var xhr = new XMLHttpRequest();
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    // console.log(this.responseText);
                    let recjson = JSON.parse(this.responseText);
                    if (recjson["body"]) {
                        setIgn(decodeURIComponent(recjson["body"]).slice(1, -1));
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "User with this id not found",
                        });
                    }
                }
            });
            xhr.open(
                "GET",
                `https://chp701oq1m.execute-api.ap-south-1.amazonaws.com/v1/get?userid=${userid}&zoneid=${serverid}`
            );
            xhr.send();
        }
    }

    const handlePayment = () => {
        var validRegex = /\S+@\S+\.\S+/;


        if (userid === "" || serverid === "") {
            Swal.fire({
                icon: 'warning',
                text: 'Please enter your user id and zone id',
            })

        }
        else if (amount === undefined) {
            Swal.fire({
                icon: 'warning',
                text: 'Please select diamond pack',
            })
        }
        else if (ign === "") {
            Swal.fire({
                icon: 'error',
                text: 'Please search your IGN',
            })
        }
        else if (!emailID.match(validRegex)) {
            Swal.fire({
                icon: 'error',
                text: 'Please enter proper email address'
            })
        }
        else {
            let props = {
                userID: userid,
                serverID: serverid,
                amount: amount,
                selectedPack: selectedPack,
                emailID: emailID,
                phoneNumber: phoneNumber,
                ordertype: 'Direct-Diamonds'

            }
            console.log("Final Json ", JSON.stringify(props))
            try {
                setRecharging(true);
                PaymentHnadler(props).then(
                    orderJson => {
                        createPaymentLink(props, orderJson)
                    }
                )
                setRecharging(false);
            }
            catch (e) {
                console.log(e);
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong! please try again after sometime or contact admin',
                })
                setRecharging(false);
            }
        }
    }

    return (
        <>

            {/* <div style={{position:'fixed', display:'block'}}> */}
            <img className="mlbb-image" src="/mlbb.jpg" />
            {/* </div> */}


            {isLive === "false" ?
                <>
                    <div className="maincontentdiv bigmargin">
                        <h1 id="store-name">Godx Official Store</h1>
                        <h2>Mobile Legends Bang bang recharge</h2>
                        <span style={{textAlign:'justify', display:'flex'}}>Our store is temporarily closed. Please visit after sometime. We appriciate your patience. Thank you</span>
                    </div>
                </>
                :
                <>

                    <div className="maincontentdiv bigmargin">
                        <h1 id="store-name">Godx Official Store</h1>
                        <h2>Mobile Legends Bang bang recharge</h2>
                        <h5 style={{ paddingBottom: "2rem", textAlign: "justify", fontSize: '0.8rem' }}>Please enter your WhatsApp number as it will help us to reach out to you incase of any issue. We will contact you only if there is any issue.</h5>
                        <TextField style={{ marginBottom: "2vh", color: 'black' }} id="phone_number" onChange={e => { setPhoneNumber(e.target.value); localStorage.setItem("phoneno", e.target.value) }}
                            value={phoneNumber} label="Enter WhatsApp number" variant="outlined"
                        />
                        <br />
                        <TextField type="email" id="email_id" label="Enter Email-ID" onChange={e => { setEmailID(e.target.value); localStorage.setItem("emailid", e.target.value) }}
                            value={emailID} variant="outlined"
                        />
                        <h6 style={{ marginTop: "1rem", marginBottom: '1rem', fontSize: '0.6rem' }}>You can edit the information and save again.</h6>
                        {userInfoSaved ?
                            <Stack sx={{ width: '100%' }} spacing={2} style={{ paddingBottom: "1vh" }}>
                                <Alert onClose={() => { setUserInfoSaved(false) }} variant="filled" severity="info">
                                    Information Saved
                                </Alert>
                            </Stack> : <></>}

                        <Button style={{ backgroundColor: 'black' }} color="primary" variant="contained" onClick={() => {
                            var validRegex = /\S+@\S+\.\S+/;
                            if (!emailID.match(validRegex)) {
                                Swal.fire({
                                    icon: 'error',
                                    text: 'Please enter proper email address'
                                })
                            }
                            else {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Information saved.',
                                })
                            }
                        }}>update</Button>

                    </div>

                    {/* <hr style={{ width: '70vw', backgroundColor: 'white' }} /> */}
                    <div style={{ width: '70vw', margin: 'auto', borderBottom: '1px solid black' }}></div>

                    <div className="maincontentdiv">
                        <h3 style={{ paddingTop: "3vh" }}>Step 1: Enter Details <HelpOutlineIcon onClick={() => { showID ? setShowID(false) : setShowID(true) }} fontSize='small' style={{}} /> </h3>
                        {showID ? <img alt="mobile legends diamonds" src='/ss1.jpg' style={{ width: "70vw", height: "auto", objectFit: "cover" }} /> : <></>}
                        <TextField
                            style={{ marginBottom: "2vh", marginTop: '1rem', borderColor: 'white' }}
                            id="user_id" label="Enter User ID" variant="outlined"
                            value={userid}
                            onChange={e => { setUserID(e.target.value.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")); setIgn(""); }}
                        />

                        <br />
                        <TextField id="zone_id" label="Enter Server ID" variant="outlined"
                            value={serverid}
                            onChange={e => { setServerID(e.target.value.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")); setIgn(""); }}
                        />
                        <h4 id="ign_id">{ign}</h4>


                        {stepOneError ?
                            <Stack sx={{ width: '100%' }} spacing={2} style={{ paddingBottom: "1vh" }}>
                                <Alert onClose={() => { setStepOneError(false) }} variant="filled" severity="error">
                                    {stepOneErrorMessage}
                                </Alert>
                            </Stack> : <></>}

                        <Button onClick={getIGN} style={{ backgroundColor: 'black', marginTop: '1rem' }} color="primary" variant="contained">Check</Button>

                    </div>

                    <div className="maincontentdiv">
                        <h3 style={{ paddingTop: "1vh" }}>Step 2: Select Pack </h3>
                        <span style={{ fontSize: "12px" }}><b>Note: Bonus diamonds will not be counted for events</b></span>

                        <FormControl component="fieldset" style={{ marginTop: '2rem' }}>

                            <InputLabel id="demo-simple-select-label">Select Pack</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPack}
                                label="Select Pack"
                                onChange={handlePackSelect}
                            >

                                {myAmountDict.map((element) => (

                                    [element.isactive === "true" ?
                                        <MenuItem key={element.name} value={element.name} onClick={() => { setAmount(element.price) }} >
                                            <div className='packdiv' key={element.name}>
                                                <span><b>{element.name}</b></span>
                                            </div>
                                        </MenuItem>
                                        :
                                        <></>
                                    ]

                                ))
                                }

                            </Select>

                            {stepTwoError ?
                                <Stack sx={{ width: '100%' }} spacing={2} style={{ paddingBottom: "1vh", marginTop: '1rem' }}>
                                    <Alert onClose={() => { setStepTwoError(false) }} variant="filled" severity="error">
                                        Please complete this step!
                                    </Alert>
                                </Stack> : <></>}
                            {rechargeWarning ?
                                <Stack sx={{ width: '100%' }} spacing={2} style={{ paddingBottom: "1vh", marginTop: '1rem' }}>
                                    <Alert onClose={() => { setStepTwoError(false) }} variant="filled" severity="warning">
                                        Please keep this app open untill you see recharge success message from this website.
                                        Also do not press back button during recharge process.
                                    </Alert>
                                </Stack> : <></>}
                            {recharging ?
                                <>
                                    <Button style={{ marginTop: '1rem' }} color="primary" disabled={true} variant="contained" startIcon={<CircularProgress />}>Recharge with INR</Button>
                                </>
                                :
                                <>
                                    <Button onClick={handlePayment} color="primary" style={{ marginBottom: "21px", marginRight: "5vw", backgroundColor: 'black', marginTop: '1rem' }} variant="contained">Recharge with INR {amount}</Button>
                                </>
                            }
                        </FormControl>
                    </div>

                </>
            }

            <NewNavbar />
            <Footer />

            <div style={{ marginBottom: '6rem' }}></div>
        </>
    )
}