
import { useState } from 'react'

import TextField from '@mui/material/TextField';
import Footer from './Footer';
import NewNavbar from './Navbar';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { generateClient } from 'aws-amplify/api';
import { getGodxUserHistory } from '../graphql/queries';

import OrdersTable from './OrdersTable';

const client = generateClient();

export default function History(params) {

    const [userID, setuserID] = useState('');
    const [serverID, setServerID] = useState('');
    const [showID, setShowID] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userOrders, setUsersOrder] = useState([]);

    async function getOrders() {
        setUsersOrder([]);
        const apiData = await client.graphql({
            query: getGodxUserHistory,
            variables: {
                userid: `${userID}-${serverID}`,
            },
        });

        if (apiData["data"]['getGodxUserHistory']) {
            setUsersOrder(JSON.parse(apiData["data"]['getGodxUserHistory']['orders']))
        }
        else {
            setUsersOrder([]);
        }
        setLoading(false);

    }

    return (
        <>

            <img className="mlbb-image" src="/mlbb.jpg" />

            <div className="maincontentdiv">
                <h1 id="store-name">Godx Official Store</h1>
                <h2 style={{margin:'0px'}}>Order History</h2>
                <h3 style={{ paddingTop: "3vh" }}>Step 1: Enter Details <HelpOutlineIcon onClick={() => { showID ? setShowID(false) : setShowID(true) }} fontSize='small' style={{}} /> </h3>
                {showID ? <img alt="mobile legends diamonds" src='/ss1.jpg' style={{ width: "70vw", height: "auto", objectFit: "cover" }} /> : <></>}
                <TextField
                    style={{ marginBottom: "2vh", marginTop: '1rem', borderColor: 'white' }}
                    id="user_id" label="Enter User ID" variant="outlined"
                    value={userID}
                    onChange={e => { setuserID(e.target.value.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")); }}
                />

                <br />
                <TextField id="zone_id" label="Enter Server ID" variant="outlined"
                    value={serverID}
                    onChange={e => { setServerID(e.target.value.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")); }}
                />


                <br />
                <Button onClick={getOrders} style={{ backgroundColor: 'black', marginTop: '1rem' }} color="primary" variant="contained">Check</Button>

            </div>

            {userOrders.length < 1 ?
                <>
                    <div className='info-container' style={{ position: 'relative', zIndex: '100', marginTop: '1rem', textAlign: 'center' }}>
                        <h3> No orders, please enter userid and serverid and try again</h3>

                    </div>
                </> :
                <>
                    <OrdersTable rows={userOrders} />

                </>}

            <NewNavbar view="history" />
            <Footer />
            <div style={{ marginBottom: '6rem' }}></div>

        </>)

}