/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGodxItems = /* GraphQL */ `
  mutation CreateGodxItems($input: CreateGodxItemsInput!) {
    createGodxItems(input: $input) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const updateGodxItems = /* GraphQL */ `
  mutation UpdateGodxItems($input: UpdateGodxItemsInput!) {
    updateGodxItems(input: $input) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const deleteGodxItems = /* GraphQL */ `
  mutation DeleteGodxItems($input: DeleteGodxItemsInput!) {
    deleteGodxItems(input: $input) {
      itemname
      isactive
      storeitems
      ef1
      ef2
      __typename
    }
  }
`;
export const createGodxTransactions = /* GraphQL */ `
  mutation CreateGodxTransactions($input: CreateGodxTransactionsInput!) {
    createGodxTransactions(input: $input) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const updateGodxTransactions = /* GraphQL */ `
  mutation UpdateGodxTransactions($input: UpdateGodxTransactionsInput!) {
    updateGodxTransactions(input: $input) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const deleteGodxTransactions = /* GraphQL */ `
  mutation DeleteGodxTransactions($input: DeleteGodxTransactionsInput!) {
    deleteGodxTransactions(input: $input) {
      trxid
      timedate
      user
      trxstatus
      purchaseitems
      amount
      ef1
      ef2
      __typename
    }
  }
`;
export const createGodxUserHistory = /* GraphQL */ `
  mutation CreateGodxUserHistory($input: CreateGodxUserHistoryInput!) {
    createGodxUserHistory(input: $input) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
export const updateGodxUserHistory = /* GraphQL */ `
  mutation UpdateGodxUserHistory($input: UpdateGodxUserHistoryInput!) {
    updateGodxUserHistory(input: $input) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
export const deleteGodxUserHistory = /* GraphQL */ `
  mutation DeleteGodxUserHistory($input: DeleteGodxUserHistoryInput!) {
    deleteGodxUserHistory(input: $input) {
      userid
      orders
      subscription
      isblocked
      __typename
    }
  }
`;
