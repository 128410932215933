import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

const PwaWithInstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default install prompt
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstalled(false);
    };

    const handleAppInstalled = () => {
      setIsInstalled(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsInstalled(true);
      });
    }
  };

  return (
    <div style={{width:'100%', display:"flex", justifyContent:'center', margin:'1rem 0rem'}}>
      {!isInstalled && (
        <Button variant='outlined' onClick={handleInstall}>
          Install App
        </Button>
      )}
    </div>
  );
};

export default PwaWithInstallButton;